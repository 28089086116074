const Pz = require("pizzicato");

const Pizzicato = (pizz, src, time, setTime) => {
  let timer;

  if (pizz) {
    const reverb = new Pz.Effects.Reverb({
      time: pizz.reverb_time,
      mix: pizz.reverb_mix,
      volume: pizz.reverb_volume,
      decay: pizz.reverb_decay,
    });

    const compressor = new Pz.Effects.Compressor({
      attack: pizz.compressor_attack,
      knee: pizz.compressor_knee,
      ratio: pizz.compressor_ratio,
      release: pizz.compressor_release,
      threshold: pizz.compressor_threshold,
      volume: pizz.compressor_volume,
    });

    const delay = new Pz.Effects.Delay({
      feedback: pizz.delay_feedback,
      mix: pizz.delay_mix,
      time: pizz.delay_time,
      volume: pizz.delay_volume,
    });

    const highPassFilter = new Pz.Effects.HighPassFilter({
      frequency: pizz.highpass_frequency,
      peak: pizz.highpass_peak,
      volume: pizz.highpass_volume,
    });

    const sound = new Pz.Sound(
      {
        source: "file",
        options: {
          path: src,
          loop: true,
        },
      },
      () => {
        if (window.location.href.includes("record")) {
          if (pizz.is_reverb === 1) {
            sound.addEffect(reverb);
          }
          if (pizz.is_compressor === 1) {
            sound.addEffect(compressor);
          }
          if (pizz.is_delay === 1) {
            sound.addEffect(delay);
          }
          if (pizz.is_highpass === 1) {
            sound.addEffect(highPassFilter);
          }
        }

        const checkTime = setInterval(() => {
          if (Pz.context.current) {
            // scroll set
            sound.pause();
            sound.play(timer, Pz.context.current);

            setTime(Pz.context.current);

            Pz.context.setting = Pz.context.current;
            Pz.context.current = null;
          } else if (localStorage.getItem("playState")) {
            // pause
            sound.pause();
            sound.volume = 0;
            Pz.context.suspend();
            clearInterval(checkTime);
            localStorage.removeItem("set");

            if (!localStorage.getItem("start")) {
              Pz.context.currentState = null;
              setTime(0);
            }

            Pz.context.setting = null;
          } else {
            if (
              localStorage.getItem("start") &&
              Pz.context.currentState &&
              !Pz.context.setting &&
              !localStorage.getItem("set")
            ) {
              // pause and start
              sound.pause();
              sound.volume = 1;
              sound.play(timer, Pz.context.currentState);
              Pz.context.currentState = null;
            } else if (Pz.context.currentState && Pz.context.setting) {
              // pause and scroll and start
              sound.pause();
              sound.volume = 1;
              sound.play(timer, Pz.context.setting);
              Pz.context.setting = null;

              localStorage.setItem("set", "set");
            } else {
              //first start
              if (!localStorage.getItem("start")) {
                localStorage.setItem("start", "start");
                Pz.context.currentState = null;
              }

              sound.volume = 1;
              sound.play(timer, 0);
            }

            setTime((prev) => prev + 1);
          }

          if (
            Math.floor(time - 1) ===
            Math.floor(sound.sourceNode.buffer.duration)
          ) {
            localStorage.removeItem("set");
            sound.pause();
            sound.volume = 0;

            clearInterval(checkTime);
            window.location.reload();
          }
        }, 1000);
      }
    );
  }
};

export default Pizzicato;
