import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import useSWR from "swr";
import {
  PlayState,
  PlayTimeState,
  PlayIdState,
  PlayTypeState,
  PreviewPlayState,
} from "../utils/atoms";
import auth from "../utils/auth";

const Pz = require("pizzicato");

const Player = () => {
  const history = useHistory();

  const [play, setPlay] = useRecoilState(PlayState);
  const [time, setTime] = useRecoilState(PlayTimeState);
  const [playId, setPlayId] = useRecoilState(PlayIdState);
  const [playType, setPlayType] = useRecoilState(PlayTypeState);
  const [preview, setPreview] = useRecoilState(PreviewPlayState);

  const [item, setItem] = useState();

  const { data } = useSWR(
    auth.getToken() &&
      playId &&
      (playType === "song"
        ? `/music/detail?target=${playId}`
        : `/record/detail?target=${playId}`)
  );

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [playId, data]);

  const audioPlayer = useRef(null);

  useEffect(() => {
    if (audioPlayer.current !== null && item) {
      if (time >= item?.detail.music_length) {
        // setTime(0);
        setPlay("play");
        document.getElementById("MainAudio").pause();
        Pz.context.currentState = time;
      }

      audioPlayer.current.style.setProperty(
        "--seek-before-width",
        (time / item?.detail.music_length) * 100 + "%"
      );
    }
  }, [time]);

  return (
    <PlayerArea
      ref={audioPlayer}
      style={{
        display:
          preview &&
          !window.location.href.includes("song/detail") &&
          !window.location.href.includes("record/detail") &&
          (window.location.href.includes("mypage/setting") ||
            !window.location.href.includes("mypage/")) &&
          !window.location.href.includes("/goldmic") &&
          !window.location.href.includes("/withdraw") &&
          !window.location.href.includes("/feed/post") &&
          !window.location.href.includes("/feed/edit") &&
          !window.location.href.includes("/studio")
            ? "block"
            : "none",
      }}
    >
      {preview && auth.getToken() && (
        <>
          <Range>
            <input
              type="range"
              max={item ? item?.detail.music_length : 0}
              value={time}
              readOnly
            />
          </Range>
          <InfoBox className={playType === "song" ? "" : "record"}>
            <CerverImage
              style={{
                background: `url(${
                  item ? item?.detail.cerver_image_url : "#3d3d4e"
                }) no-repeat center/cover`,
              }}
              onClick={() => {
                if (playType === "song")
                  history.push(`/song/detail?id=${playId}`);
                else history.push(`/record/detail?id=${playId}`);
              }}
            />
            <InfoText
              onClick={() => {
                if (playType === "song")
                  history.push(`/song/detail?id=${playId}`);
                else history.push(`/record/detail?id=${playId}`);
              }}
            >
              <h1>
                {playType === "song"
                  ? item?.detail.music_name
                  : item?.detail.record_title}
              </h1>
              <p>
                {playType === "song"
                  ? item?.detail.music_singer
                  : item?.detail.nickname}
              </p>
            </InfoText>
            <PlayIcon
              src={
                require(play === "play"
                  ? "../assets/play-icon.svg"
                  : "../assets/pause-icon-circle.svg").default
              }
              alt="play-icon"
              onClick={() => {
                if (play === "play") {
                  localStorage.removeItem("playState");
                  setPlay("pause");
                  document.getElementById("MainAudio").play();
                } else {
                  localStorage.setItem("playState", "pause");
                  setPlay("play");
                  document.getElementById("MainAudio").pause();
                  Pz.context.currentState = time;
                }
              }}
            />
            <CancleImage
              src={require("../assets/tag-delete.svg").default}
              alt="tag-delete"
              onClick={() => {
                // setTime(0);
                localStorage.setItem("playState", "pause");
                setPlay("play");
                Pz.context.currentState = time;
                document.querySelector("audio").currentTime = 0;
                document.getElementById("MainAudio").pause();
                // localStorage.setItem("playState", "pause");
                // document.getElementById("MainAudio").pause();
                // document.querySelector("audio").currentTime = 0;
                setPreview(false);
              }}
            />
          </InfoBox>
        </>
      )}
    </PlayerArea>
  );
};

export default Player;

const CancleImage = styled.img`
  width: 11px;
`;

const PlayIcon = styled.img`
  width: 36px;
  margin-right: 15px;
`;

const InfoText = styled.div`
  width: 100%;
  margin-right: 10px;

  & h1 {
    font-size: 13px;
    line-height: 1;
    color: #f9f9f9;
    margin-bottom: 5px;
  }

  & p {
    font-size: 11px;
    line-height: 1;
    color: #6b6b77;
  }
`;

const CerverImage = styled.div`
  min-width: 36px;
  width: 36px;
  height: 36px;
  margin-right: 10px;
  border-radius: 50%;

  .record & {
    border-radius: 5px;
  }
`;

const InfoBox = styled.div`
  padding: 11px 18px;
  display: flex;
  align-items: center;
`;

const PlayerArea = styled.div`
  padding-bottom: 56px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(61, 61, 78, 0.95);
  z-index: 10;
`;

const Range = styled.div`
  width: 100%;
  height: 2px;
  overflow: hidden;

  & input {
    width: 100%;
    position: relative;
    -webkit-appearance: none;
    transform: translateY(-13px);
  }

  & input::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    border-radius: 2px;
    background: #32323d;
  }

  & input::before {
    position: absolute;
    content: "";
    left: 0;
    width: var(--seek-before-width);
    height: 2px;
    border-radius: 2px;
    background: #855de1;
  }

  & input::-webkit-slider-thumb {
    display: none;
  }
`;
