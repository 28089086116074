import { atom } from "recoil";

export const PlayState = atom({
  key: "PlayState",
  default: "play",
});

export const PlaySrcState = atom({
  key: "PlaySrcState",
  default: require("../assets/iosnp.m4a").default,
});

export const PlayIdState = atom({
  key: "PlayIdState",
  default: "",
});

export const PlayTypeState = atom({
  key: "PlayTypeState",
  default: "",
});

export const PlayTimeState = atom({
  key: "PlayTimeState",
  default: 0,
});

export const PreviewPlayState = atom({
  key: "PreviewPlayState",
  default: false,
});

export const StudioRecordFiles = atom({
  key: "StudioRecordFiles",
  default: [],
});

export const StudioRecordTracks = atom({
  key: "StudioRecordTracks",
  default: [],
});
