import React from "react";
import { Redirect, Route } from "react-router-dom";
import auth from "../utils/auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.getToken() !== null &&
        auth.getToken() !== "null" &&
        auth.getToken() !== undefined &&
        auth.getToken() !== "undefined" ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/`} />
        )
      }
    />
  );
};

export default PrivateRoute;
