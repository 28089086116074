import React, {
  useEffect,
  // useRef,
  useState,
} from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import {
  Root,
  FindPassword,
  Join,
  AppLogin,
  SnsJoin,
  Song,
  Record,
  SongNew,
  SongPopul,
  RecordNew,
  RecordPopul,
  SongDetail,
  RecordDetail,
  Search,
  StudioStorage,
  Feed,
  FeedPost,
  FeedDetail,
  FeedEdit,
  Mypage,
  Setting,
  Profile,
  GoldMic,
  Purchase,
  Withdraw,
  Alarm,
  Info,
  Apply,
  MySong,
  MyRecord,
  MyFeed,
  MyFan,
  AlarmMain,
  Ques,
  QuesDetial,
  User,
  QuesWrite,
  Terms,
  Private,
  UserSong,
  UserRecord,
  UserFeed,
  UserFan,
  Faq,
  Event,
  EventDetail,
  Studio,
  AppleLogin,
  AppleJoin,
} from "../pages";
import { api } from "../utils/api";
import { SWRConfig } from "swr";
import { useRecoilState } from "recoil";
import { PlayState, PlaySrcState, PlayTimeState } from "../utils/atoms";
import Player from "../components/Player";

import koMsg from "../lang/ko.json";
import enMsg from "../lang/en.json";

// import Pizzicato from "pizzicato";

import PrivateRoute from "../components/PrivateRoute";
import Pizzicato from "../components/Pizzicato";
const Pz = require("pizzicato");

const App = () => {
  const [play, setPlay] = useRecoilState(PlayState);
  const [src, setSrc] = useRecoilState(PlaySrcState);
  const [time, setTime] = useRecoilState(PlayTimeState);
  const [pizz, setPizz] = useState([]);

  const initKakao = () => {
    const jsKey = "ae3a70357f0c796e4ab2f20c2f440c8d";
    const Kakao = window.Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(jsKey);
    }
  };

  useEffect(() => {
    initKakao();
  }, []);

  const swrConfig = {
    fetcher(url, params) {
      return api.get(url, { params }).then((res) => {
        if (url.includes("record") && res.data.data.detail) {
          setPizz(res.data.data.detail);
        }
        return res.data?.data || res.data;
      });
    },
  };

  //ios audio context
  document.addEventListener("click", () => {
    Pz.context
      .resume()
      .then(() => console.log("success"))
      .catch(() => console.log("fail"));
  });

  useEffect(() => {
    if (play === "pause") {
      Pz.context
        .resume()
        .then(() => console.log("success"))
        .catch(() => console.log("fail"));

      Pizzicato(pizz, src, time, setTime);
    }
  }, [src, play]);

  const locale = navigator.language ?? "ko-KR";
  const messages = { "en-US": enMsg, "ko-KR": koMsg, ko: koMsg }[locale];

  console.log(locale);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <SWRConfig value={swrConfig}>
        <BrowserRouter>
          <audio
            preload="none"
            id="MainAudio"
            muted={true}
            onTimeUpdate={() => {
              // setTime(
              //   Math.floor(document.getElementById("MainAudio").currentTime)
              // );
            }}
            onEnded={() => {
              // setTime(0);
              setPlay("play");
              document.getElementById("MainAudio").pause();
              Pz.context.currentState = time;

              // if (sound.current !== null) {
              //   sound.current.stop();
              // }
            }}
          >
            {/* <source src={src} type="audio/mp4" />
          <source src={src} type="audio/mpeg" /> */}
          </audio>
          <Switch>
            <Route exact path="/" component={Root} />
            <Route exact path="/join" component={Join} />
            <Route exact path="/app/login" component={AppLogin} />
            <Route exact path="/app/apple/login" component={AppleLogin} />
            <Route exact path="/apple/join" component={AppleJoin} />
            <Route exact path="/snsJoin" component={SnsJoin} />
            <Route exact path="/findPassword" component={FindPassword} />

            <Route exact path="/terms" component={Terms} />
            <Route exact path="/private" component={Private} />

            <Route exact path="/song" component={Song} />
            <Route exact path="/song/newList" component={SongNew} />
            <Route exact path="/song/popular" component={SongPopul} />
            <PrivateRoute exact path="/song/detail" component={SongDetail} />

            <Route exact path="/record" component={Record} />
            <Route exact path="/record/newList" component={RecordNew} />
            <Route exact path="/record/popular" component={RecordPopul} />
            <PrivateRoute
              exact
              path="/record/detail"
              component={RecordDetail}
            />

            <Route exact path="/search" component={Search} />
            <Route exact path="/alarm" component={AlarmMain} />

            <Route exact path="/studio" component={Studio} />
            <Route exact path="/studio/storage" component={StudioStorage} />

            <PrivateRoute exact path="/feed" component={Feed} />
            <Route exact path="/feed/post" component={FeedPost} />
            <Route exact path="/feed/edit" component={FeedEdit} />
            <Route exact path="/feed/detail" component={FeedDetail} />

            <Route exact path="/mypage" component={Mypage} />
            <Route exact path="/mypage/setting" component={Setting} />
            <Route exact path="/mypage/profile" component={Profile} />
            <Route exact path="/mypage/info" component={Info} />
            <Route exact path="/mypage/alarm" component={Alarm} />
            <Route exact path="/mypage/song" component={MySong} />
            <Route exact path="/mypage/record" component={MyRecord} />
            <Route exact path="/mypage/feed" component={MyFeed} />
            <Route exact path="/mypage/fan" component={MyFan} />
            <Route exact path="/mypage/faq" component={Faq} />
            <Route exact path="/mypage/question" component={Ques} />
            <Route
              exact
              path="/mypage/question/detail"
              component={QuesDetial}
            />
            <Route exact path="/mypage/question/write" component={QuesWrite} />
            <Route exact path="/mypage/event" component={Event} />
            <Route exact path="/mypage/event/detail" component={EventDetail} />

            <Route exact path="/mypage/user" component={User} />
            <Route exact path="/mypage/user/song" component={UserSong} />
            <Route exact path="/mypage/user/record" component={UserRecord} />
            <Route exact path="/mypage/user/feed" component={UserFeed} />
            <Route exact path="/mypage/user/fan" component={UserFan} />

            <Route exact path="/goldmic" component={GoldMic} />
            <Route exact path="/purchase" component={Purchase} />
            <Route exact path="/withdraw" component={Withdraw} />

            <Route exact path="/composer/apply" component={Apply} />
          </Switch>
          <Player />
        </BrowserRouter>
      </SWRConfig>
    </IntlProvider>
  );
};

export default App;
