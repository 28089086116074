import Axios from "axios";
import auth from "./auth";
export const endpoint = "https://api.lisnup.kr/api";
// export const endpoint = "http://127.0.0.1:8000/api";
//export const endpoint = "https://api.listenup.fifteenh.io/api";
export const api = Axios.create({
  baseURL: endpoint,
  headers: {
    Authorization: `Bearer ${auth.getToken()}`,
  },
});
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config._retry
    ) {
      try {
        console.log(auth.getRefreshToken());
        console.log(`${auth.getToken()}`);
        if (auth.getRefreshToken() !== "undefined") {
          const {
            data: { success, data, alert },
          } = await api.post("/refresh/token", {
            refresh_token: auth.getRefreshToken(),
          });
          if (data.access_token) {
            auth.setToken(data.access_token);
            //api.defaults.headers.common["Authorization"] = 'Bearer ' + data.access_token;
          }
          if (data.refresh_token) {
            auth.setRefreshToken(data.refresh_token);
          }
          return api.request(error.config);
        } else {
          return null;
        }
      } catch (err) {
        return null;
      }
    }
    return Promise.reject(error);
  }
);
