import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import App from "./client/App";

import "./styles/reset.css";
import "./styles/index.css";
import "./styles/font.css";

import { RecoilRoot } from "recoil";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <RecoilRoot>
    <GoogleOAuthProvider clientId="361942822954-0739briinu8flp10huqjgl1cvgmcnhpp.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </RecoilRoot>,
  document.getElementById("root")
);
