import loadable from "@loadable/component";

export const Root = loadable(() => import("./Root"));
export const AppLogin = loadable(() => import("./user/AppLogin"));
export const Join = loadable(() => import("./user/Join"));
export const AppleLogin = loadable(() => import("./user/AppleLogin"));
export const AppleJoin = loadable(() => import("./user/AppleJoin"));
export const SnsJoin = loadable(() => import("./user/SnsJoin"));
export const FindPassword = loadable(() => import("./user/FindPassword"));

export const Song = loadable(() => import("./song/index"));
export const SongNew = loadable(() => import("./song/NewList"));
export const SongPopul = loadable(() => import("./song/Popular"));
export const SongDetail = loadable(() => import("./song/Detail"));

export const Record = loadable(() => import("./record/index"));
export const RecordNew = loadable(() => import("./record/NewList"));
export const RecordPopul = loadable(() => import("./record/Popular"));
export const RecordDetail = loadable(() => import("./record/Detail"));

export const Search = loadable(() => import("./search/index"));

export const Studio = loadable(() => import("./studio/Studio"));
export const StudioStorage = loadable(() => import("./studio/StudioStorage"));

export const Feed = loadable(() => import("./feed/index"));
export const FeedPost = loadable(() => import("./feed/Post"));
export const FeedEdit = loadable(() => import("./feed/Edit"));
export const FeedDetail = loadable(() => import("./feed/Detail"));

export const Mypage = loadable(() => import("./mypage/index"));
export const Setting = loadable(() => import("./mypage/Setting"));
export const Profile = loadable(() => import("./mypage/Profile"));
export const Info = loadable(() => import("./mypage/Info"));
export const Alarm = loadable(() => import("./mypage/Alarm"));
export const MySong = loadable(() => import("./mypage/Song"));
export const MyRecord = loadable(() => import("./mypage/Record"));
export const MyFeed = loadable(() => import("./mypage/Feed"));
export const MyFan = loadable(() => import("./mypage/Fan"));
export const Faq = loadable(() => import("./question/FAQ"));
export const Ques = loadable(() => import("./question/index"));
export const QuesDetial = loadable(() => import("./question/Detail"));
export const QuesWrite = loadable(() => import("./question/Write"));
export const Event = loadable(() => import("./event/Event"));
export const EventDetail = loadable(() => import("./event/Detail"));

export const User = loadable(() => import("./profile/User"));
export const UserSong = loadable(() => import("./profile/Song"));
export const UserRecord = loadable(() => import("./profile/Record"));
export const UserFeed = loadable(() => import("./profile/Feed"));
export const UserFan = loadable(() => import("./profile/Fan"));

export const GoldMic = loadable(() => import("./goldMic/index"));
export const Purchase = loadable(() => import("./goldMic/Purchase"));
export const Withdraw = loadable(() => import("./goldMic/Withdraw"));

export const Apply = loadable(() => import("./composer/Apply"));

export const AlarmMain = loadable(() => import("./Alarm"));

export const Private = loadable(() => import("./terms/Private"));
export const Terms = loadable(() => import("./terms/Terms"));
